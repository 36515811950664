<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 mb-1">
      <b-col
        class="d-xs-flex align-items-center px-2"
        style="max-height: 150px;"
      >
        <b-row
          style="padding: 58px 1rem 0;"
          class="align-items-center m-0"
        >
          <b-col
            class="d-xs-flex align-items-center p-0"
          >
            <LocaleFlag v-if="isRegister" />
          </b-col>

          <b-col
            class="d-xs-flex text-right p-0"
          >
            <b-link
              v-if="isRegister"
              class="align-items-center justify-content-center"
            >
              <b-link :to="{name:'auth-login'}">
                <b-button
                  variant="primary"
                  size="sm"
                >
                  {{ $t('auth.signIn') }}
                </b-button>
              </b-link>
            </b-link>
          </b-col>
        </b-row>

        <b-col
          lg="12"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Login"
            />
          </div>
        </b-col>
      </b-col>

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-lg-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >

          <b-row
            class="align-items-center m-0"
          >
            <b-col
              class="d-xs-flex align-items-center  p-0"
            >
              <Logo
                name="salmon.png"
                image-style="width: 180px"
              />

              <template>
                <div v-if="isLogin">
                  <b-card-text
                    class="text-center"
                    style="line-height: 17px; margin: 25px 0 40px!important; font-size: 14px;"
                  >
                    {{ $t('auth.login.headerMessage') }}
                  </b-card-text>
                </div>
              </template>
            </b-col>
          </b-row>

          <router-view />

          <b-button
            v-if="isRegister"
            block
            variant="outline-primary"
            class="mt-5"
            to="#"
          >
            <span
              class="font-small-2"
              style="letter-spacing: 0.4px"
            >
              {{ $t('auth.wannaShowMyBrandHistory') }}
            </span>
          </b-button>
          <template v-if="isRegister || isLogin">
            <ul
              class="auth-footer-ul mt-2"
            >
              <li>
                <a
                  href="https://www.salmonconsultoria.com.br/"
                  target="_blank"
                >{{ $t('auth.about') }}</a>
              </li>
              <li>
                <a
                  href="https://www.salmonconsultoria.com.br/"
                  target="_blank"
                >{{ $t('auth.privacy') }}</a>
              </li>
              <li>
                <a
                  href="https://www.salmonconsultoria.com.br/"
                  target="_blank"
                >{{ $t('auth.terms') }}</a>
              </li>
              <div style="display: flex; align-items: center; justify-content: center; flex-wrap: wrap;">
                <li>
                  <LocaleLink />
                </li>
                <li>
                  &copy; {{ new Date().getFullYear() }} salmon
                </li>
              </div>
            </ul>
          </template>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BImg, BButton, BCardText, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store/index'
import LocaleFlag from '@/views/components/LocaleFlag.vue'
import Logo from '@/views/components/Logo.vue'
import LocaleLink from '@/views/components/LocaleLink.vue'

export default {
  googleImg: require('@/assets/images/svg/google-color.svg'),
  linkedinImg: require('@/assets/images/svg/linkedin.svg'),
  facebookImg: require('@/assets/images/svg/facebook.svg'),

  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    LocaleFlag,
    BRow,
    BCol,
    BLink,
    BImg,
    BButton,
    BCardText,
    Logo,
    LocaleLink,
  },

  computed: {
    isLogin() {
      return this.$route.name === 'auth-login'
    },

    isRegister() {
      return this.$route.name === 'auth-register'
    },

    imgUrl() {
      return ({
        'auth-login': {
          light: require('@/assets/images/pages/login/rpas_apr_mockup_banner_5.png'),
          dark: require('@/assets/images/pages/login/rpas_apr_mockup_banner_5.png'),
        },
        'auth-register': {
          light: require('@/assets/images/pages/register-v2.svg'),
          dark: require('@/assets/images/pages/register-v2-dark.svg'),
        },
        'auth-forgot-password': {
          light: require('@/assets/images/pages/forgot-password-v2.svg'),
          dark: require('@/assets/images/pages/forgot-password-v2-dark.svg'),
        },
        'auth-reset-password': {
          light: require('@/assets/images/pages/forgot-password-v2.svg'),
          dark: require('@/assets/images/pages/forgot-password-v2-dark.svg'),
        },
      })[this.$route.name][store.state.appConfig.layout.skin]
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/base/plugins/ui/breakpoints';

@media (max-width: $usn-large) {
  .auth-wrapper {
    background-color: #fff;
  }
}
</style>

<style lang="scss" scoped>
.font-weight-boldest {
  font-weight: 700;
}
.auth-brand-name {
  font-size: 20px;
  line-height: 90%;
}
.google-button {
  border-color: #DDDBE2!important;
}
.google-logo {
  width: 16px;
  margin-right: .5rem;
}
.auth-footer-ul {
  list-style: none;
  display: flex;
  padding: 0;
  align-content: space-around;
  flex-wrap: wrap;
  justify-content: center;
}
.auth-footer-ul > li {
  margin-right: 18px;
  margin-bottom: 20px;
}
.auth-footer-ul > li a {
  color: #5E5873;
}
</style>
