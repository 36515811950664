<template>
  <b-link class="d-lg-flex align-items-center justify-content-center">
    <h4
      class="font-weight-boldest text-center mb-0"
    >
      <img
        :src="$options[name]"
        alt="Salmon"
        :style="imageStyle"
      >
    </h4>
  </b-link>
</template>

<script>
import {
  BLink,
} from 'bootstrap-vue'

export default {
  name: 'Logo',
  components: {
    BLink,
  },
  props: {
    name: { type: String, default: 'salmon.png' },
    imageStyle: { type: String, default: 'width: 75px' },
  },
  // eslint-disable-next-line global-require
  'salmon.png': require('@/assets/images/logo/salmon.png'),
  // eslint-disable-next-line global-require
  'logo-with-name-below.svg': require('@/assets/images/logo/logo-with-name-below.svg'),
}
</script>

<style lang="scss" scoped>
.font-weight-boldest {
  font-weight: 700;
}
</style>
